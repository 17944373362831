export const BASE_API_URL = "https://telegappapi.pay-way.uz/api"; //PROD
// export const BASE_API_URL = "https://tgapi.test.1pay.uz/tgapi"; //TEST

export const STATIC_URL = "https://static.1pay.uz";

export const TG_USER_ID = window.Telegram.WebApp.initDataUnsafe?.user?.id || 0;

export const CLIENT_SALT = localStorage.getItem(`clientSalt-${TG_USER_ID}`);

export const TOKEN = localStorage.getItem(`token-${TG_USER_ID}`);

export const INIT_DATA = window.Telegram.WebApp.initData;
// export const INIT_DATA = "query_id=AAE8nr1cAAAAADyevVzh7R8J&user=%7B%22id%22%3A1555930684%2C%22first_name%22%3A%22%D0%90%D1%81%D1%80%D0%B0%D0%BD%D0%BE%D0%B2%20%D0%91%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22asrnvb%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%2C%22photo_url%22%3A%22https%3A%5C%2F%5C%2Ft.me%5C%2Fi%5C%2Fuserpic%5C%2F320%5C%2FFtX3cSz_Ta4GCKBAW5SYinR7y9-FBBrS1zkDbeJ024Y.svg%22%7D&auth_date=1733996852&signature=GJtLgAtAsW9i5frfPgbpSGfMdMO6zdoXlOizTfPYkvuVK1xJIh_dSPuje0P_Oa_PoJ9Ql0Pxk4v5eAerHmwYDg&hash=d62fe318da2c24f5387d11a570e208777d1a6f98bccd110535c19ae0551f9a7c";
