import React, { useEffect, useState } from "react";
import "./AllCards.css";
import { useTranslation } from "react-i18next";
import SectionHeader from "../../layouts/SectionHeader";
import { CiCirclePlus } from "react-icons/ci";
import { ICard } from "../Payment/Cards/ICard";
import { cardStore } from "../../stores/cardStore";
import { Link } from "react-router-dom";
import CCard from "../../components/CCard";

const AllCards: React.FC = () => {
  const [cards, setCards] = useState<ICard[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const fetchCards = async () => {
    try {
      setIsLoading(true);

      const response: ICard[] = await cardStore.getList();

      const filteredCards = response.filter(
        (card) => card.id !== "ofb" && card.id !== "sbp" && card.type !== "19"
      );

      setCards(filteredCards);
    } catch (error) {
      console.log("Error in fetchCards", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCards();
  }, []);

  return (
    <>
      <SectionHeader title={t("labels.cards")} />

      <div className="allcards wrapper">
        <div className="allcards__header">
          <p className="allcards__title">
            {t("labels.cards")}({cards?.length || 0})
          </p>

          <Link to={"/payment/addcard"} className="allcards__add">
            <CiCirclePlus className="allcards__icon" /> {t("labels.add")}
          </Link>
        </div>

        <div className="allcards__cards">
          {isLoading && <div className="card__loading"></div>}

          {cards?.map((card) => {
            return <CCard card={card} key={card.id} />;
          })}
        </div>
      </div>
    </>
  );
};

export default AllCards;
