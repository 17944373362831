import React from "react";
import "./ChangePassword.css";
import SectionHeader from "../../layouts/SectionHeader";
import { t } from "i18next";

const ChangePassword: React.FC = () => {
  return (
    <>
      <SectionHeader title={t("labels.changePasswordTitle")} />

      <div className="changePassword wrapper"></div>
    </>
  );
};

export default ChangePassword;
