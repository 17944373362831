import { makeAutoObservable } from "mobx";
import apiClient from "../services/apiClient";

class HistoryStore {
  historyCache = null;

  constructor() {
    makeAutoObservable(this);
  }

  getHistory = async (
    cardId?: number,
    lastTid?: number,
    from?: string,
    to?: string
  ) => {
    if (this.historyCache) {
      return this.historyCache;
    }

    const params = {
      cardId,
      lastTid,
      from,
      to,
    };

    try {
      const response = await apiClient.get("/history", { params });

      this.historyCache = response.data;

      return response.data;
    } catch (error) {
      console.log("Error in getHistory", error);
    }
  };

  reGetHistory = async () => {
    this.historyCache = null;

    return this.getHistory();
  };
}

export const historyStore = new HistoryStore();
