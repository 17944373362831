import React, { useState, useEffect } from "react";
import "./Register.css";
import pwLight from "../../../assets/images/pw-light.svg";
import pwDark from "../../../assets/images/pw-dark.svg";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { authStore } from "../../../stores/authStore";
import { themeStore } from "../../../stores/themeStore";
import CBack from "../../../components/CBack";
import CInput from "../../../components/CInput";
import CButton from "../../../components/CButton";
import { SubmitHandler, useForm } from "react-hook-form";

interface FormData {
  code: string;
}

const RegisterConfirm: React.FC = observer(() => {
  const [message, setMessage] = useState("");
  const [timer, setTimer] = useState(120);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    let interval: NodeJS.Timer | undefined;

    if (isTimerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsTimerActive(false);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isTimerActive, timer]);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      setIsLoading(true);
      const response = await authStore.registerConfirm(data.code);

      if (response.message) {
        setMessage(response.message);
        return;
      }

      if (response.result) {
        navigate("/auth/login");
      }
    } catch (error) {
      console.error("Error during registerConfirm:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const registerResend = async () => {
    try {
      const response = await authStore.registerResend();

      if (response.message) {
        setMessage(response.message);
        return;
      }

      setTimer(60);
      setIsTimerActive(true);
    } catch (error) {
      console.error("Error during registerResend:", error);
    }
  };

  return (
    <div className="register wrapper">
      <div style={{ width: "100%" }}>
        <CBack />
      </div>

      <img
        src={themeStore.isDarkTheme ? pwDark : pwLight}
        alt="pw"
        className="auth__logo"
      />

      <form className="register__form" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <p className="register__form__label">{t("code.title")}</p>
          <CInput
            placeholder={t("code.placeholder")}
            inputMode="numeric"
            maxLength={6}
            {...register("code", {
              required: t("error.codeRequired"),
              minLength: {
                value: 6,
                message: t("error.codeMinLength"),
              },
              maxLength: {
                value: 6,
                message: t("error.codeMaxLength"),
              },
            })}
          />

          {errors.code && (
            <span className="danger__text register__error">
              {errors.code.message}
            </span>
          )}

          {message && <p className="error__message">{message}</p>}

          {timer === 0 ? (
            <p onClick={registerResend} className="auth__timer">
              {t("auth.resendSms")}
            </p>
          ) : (
            <p className="auth__timer">
              {timer} {t("labels.sec")}
            </p>
          )}
        </div>

        <CButton
          title={t("labels.confirm")}
          variant="success"
          isLoading={isLoading}
        />
      </form>
    </div>
  );
});

export default RegisterConfirm;
