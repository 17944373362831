import { useTranslation } from "react-i18next";

export const useTypeCountryResidenceOptions = () => {
  const { t } = useTranslation();

  const typeCountryResidenceOptions = [
    { id: 1, title: t("country.uzbekistan"), value: "UZ" },
  ];

  return typeCountryResidenceOptions;
};
