import React, { useEffect, useState } from "react";
import "./Ident.css";
import { useTranslation } from "react-i18next";
import SectionHeader from "../../layouts/SectionHeader";
import CButton from "../../components/CButton";
import CStatusIcon from "../../components/CStatusIcon";
import { LuUserCircle } from "react-icons/lu";
import { accountStore } from "../../stores/accountStore";
import { IAccountInfo } from "../../models/IAccountInfo";
import { ILevelFeature, ILevelValue } from "./Ident.types";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCheckCircle } from "react-icons/fa";
import { VscChromeClose } from "react-icons/vsc";
import { IUserLevel } from "./IUserLevel";

const Ident: React.FC = () => {
  const [userLevels, setUserLevels] = useState<IUserLevel[]>([]);
  const [accountInfo, setAccountInfo] = useState<IAccountInfo>();
  const [selectedLevel, setSelectedLevel] = useState<IUserLevel>();
  const [isIdent, setIsIdent] = useState(false);
  const [levelValues, setLevelValues] = useState<ILevelValue[]>([]);
  const [levelFeatures, setLevelFeatures] = useState<ILevelFeature[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const status = () => {
    if (isIdent) {
      return "Gold";
    }

    if (!isIdent) {
      return "Silver";
    }

    if (accountInfo?.levelId === 2) {
      return "Gold";
    }

    if (accountInfo?.levelId === 3) {
      return "Gold";
    }

    if (accountInfo?.levelId === 4) {
      return "Platinum";
    }

    return "Silver";
  };

  const fetchUserLevels = async () => {
    try {
      const response = await accountStore.fetchUserLevels();

      setSelectedLevel(response[0]);

      setUserLevels(response);
    } catch (error) {
      console.log("Error in fetchUserLevels", error);
    }
  };

  const fetchIsIdent = async () => {
    try {
      const response = await accountStore.isIdentAccount();

      setIsIdent(response);

      return response;
    } catch (error) {
      console.log("Error in fetchIsIdent", error);
    }
  };

  const getAccountInfo = async () => {
    try {
      const response = await accountStore.getAccountInfo();

      setAccountInfo(response);
    } catch (error) {
      console.log("Error in getAccountInfo", error);
    }
  };

  const onIdent = async () => {
    try {
      setIsLoading(true);

      await accountStore.onIdent();

      navigate("/profile/ident/create");
    } catch (error) {
      console.log("Error in onIdent", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLevelClick = (userLevel: IUserLevel) => {
    setSelectedLevel(userLevel);
  };

  useEffect(() => {
    fetchUserLevels();
    getAccountInfo();
    fetchIsIdent();
  }, []);

  useEffect(() => {
    if (selectedLevel?.values) {
      try {
        const parsedValues = JSON.parse(selectedLevel.values);
        setLevelValues(parsedValues);
      } catch (error) {
        console.log("Error in parsing level values", error);
      }
    } else {
      setLevelValues([]);
    }

    if (selectedLevel?.features) {
      try {
        const parsedFeatures = JSON.parse(selectedLevel.features);

        setLevelFeatures(parsedFeatures);
      } catch (error) {
        console.log("Error in parsing level features", error);
      }
    } else {
      setLevelFeatures([]);
    }
  }, [selectedLevel]);

  return (
    <>
      <SectionHeader backTo="/payment" title={t("profile.ident")} />

      <div className="ident wrapper">
        <div className="ident__content">
          <div className="ident__section">
            <div className="ident__status">
              <CStatusIcon status={status()} />
              <p>{t("ident.activeStatus")}</p>
            </div>

            {accountInfo?.levelId === 2 ||
            accountInfo?.levelId === 3 ||
            accountInfo?.levelId === 4 ? (
              <Link
                to={"/profile/ident/data"}
                className="ident__status"
                state={{ accountInfo: JSON.stringify(accountInfo) }}
              >
                <LuUserCircle />
                <p>{t("ident.dataUser")}</p>
              </Link>
            ) : (
              <div className="ident__status">
                <LuUserCircle />
                <p>{t("ident.dataUser")}</p>
              </div>
            )}
          </div>

          <div className="ident__section">
            {userLevels.map((userLevel) => (
              <div
                key={userLevel.id}
                className={`ident__status ${
                  selectedLevel?.id === userLevel.id
                    ? "ident__status--selected"
                    : ""
                }`}
                onClick={() => handleLevelClick(userLevel)}
              >
                <CStatusIcon status={userLevel?.name} />
                <p>{userLevel?.name}</p>
              </div>
            ))}
          </div>

          {selectedLevel && levelValues.length > 0 && (
            <div className="ident__details">
              {levelValues?.map((value: ILevelValue, index: number) => (
                <div key={index} className="ident__details__content">
                  <p className="ident__details__name">{value.name}</p>
                  <p className="ident__details__value">{value.value}</p>
                </div>
              ))}

              {levelFeatures?.map((feature: ILevelFeature, index: number) => (
                <div key={index} className="ident__details__content">
                  <p className="ident__details__name">
                    <span>
                      {feature.available ? (
                        <FaRegCheckCircle className="ident__check" />
                      ) : (
                        <VscChromeClose className="ident__circle" />
                      )}
                    </span>
                    <span>{feature.name}</span>
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>

        {!isIdent && (
          <CButton
            title={t("ident.completeIdent")}
            onClick={onIdent}
            isLoading={isLoading}
          />
        )}
      </div>
    </>
  );
};

export default Ident;
