import React, { useCallback, useEffect, useState } from "react";
import "./TransferResult.css";
import { Link, useLocation } from "react-router-dom";
import SectionHeader from "../../../layouts/SectionHeader";
import { cardStore } from "../../../stores/cardStore";
import CButton from "../../../components/CButton";
import { themeStore } from "../../../stores/themeStore";
import pwDark from "../../../assets/images/pw-dark.svg";
import pwLight from "../../../assets/images/pw-light.svg";
import CDevider from "../../../components/CDevider";
import CLoading from "../../../components/CLoading";
import CStatusTicket from "../../../components/CStatusTicket";
import { t } from "i18next";
import { pointStore } from "../../../stores/pointStore";

interface IField {
  note: string;
  value: string;
}
export interface ITicket {
  id: number;
  amount: number;
  amountCommission: number;
  canceled: boolean;
  favouriteSaved: boolean;
  fields: IField[];
}

const TransferResult: React.FC = () => {
  const [ticket, setTicket] = useState<ITicket>();
  const [inviteLink, setInviteLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const processCardTransaction = location.state.response;

  const getTicket = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await cardStore.ticket(processCardTransaction.optoken);
      setTicket(response);
    } catch (error) {
      console.log("Error in getTicket", error);
    } finally {
      setIsLoading(false);
    }
  }, [processCardTransaction.optoken]);

  const fetchInviteLink = async () => {
    try {
      const response = await pointStore.getInvite();

      setInviteLink(response?.result);
    } catch (error) {
      console.log("Error in fetchInviteLink", error);
    }
  };

  const shareTicket = () => {
    if (!ticket) return;

    const ticketDetails = ticket.fields
      .filter((field) => field.note !== "Назначение")
      .map((field) => {
        let icon = "ℹ️";

        if (field.note === "Номер платежа") {
          icon = "#️⃣";
        } else if (field.note === "С карты" || field.note === "На карту") {
          icon = "💳";
        } else if (
          field.note === "Сумма перевода" ||
          field.note === "Сумма оплаты"
        ) {
          icon = "💵";
        } else if (field.note === "Комиссия") {
          icon = "💵";
        } else if (field.note === "Дата проведения") {
          icon = "🕓";
        }
        return `${icon}${field.note}: ${field.value}`;
      })
      .join("\n");

    const shareMessage = `✅${t(
      "labels.transferByPayWayApp"
    )}\n--------------\n${ticketDetails}\n--------------\n${inviteLink}`;

    if (window.Telegram?.WebApp?.openTelegramLink) {
      window.Telegram.WebApp.openTelegramLink(
        `https://t.me/share/url?url=${encodeURIComponent(shareMessage)}`
      );
    }
  };

  const reGetList = async () => {
    try {
      await cardStore.reGetList();
    } catch (error) {
      console.log("Error in reGetList", error);
    }
  };

  useEffect(() => {
    reGetList();
    fetchInviteLink();

    setTimeout(() => {
      cardStore.getTotalAmount();
    }, 2000);
  }, []);

  useEffect(() => {
    getTicket();
  }, [getTicket]);
  return (
    <>
      <SectionHeader title={t("transaction.resultTitle")} />

      <div className="transferresult wrapper">
        <div
          className="center"
          style={{ flexDirection: "column", gap: "10px" }}
        >
          <img
            src={themeStore.isDarkTheme ? pwDark : pwLight}
            alt="pw"
            className="transferresult__img"
          />

          <div className="center">{isLoading && <CLoading />}</div>
        </div>

        {!isLoading && (
          <div className="transferresult__fields">
            <CStatusTicket status="done" />

            <CDevider />

            {ticket?.fields?.map((field, index) => {
              return (
                <div key={index} className="transferresult__field">
                  <p className="transferresult__note">{field.note}</p>
                  <p className="transferresult__value">{field.value}</p>
                </div>
              );
            })}
          </div>
        )}

        <div className="transferresult__actions">
          <CButton title={t("button.share")} onClick={shareTicket} />

          <Link to={"/transfer"} className="transferresult__btn">
            <CButton title={t("button.goHome")} />
          </Link>
        </div>
      </div>
    </>
  );
};

export default TransferResult;
