import React, { useState } from "react";
import "./HouseFavoritePay.css";
import SectionHeader from "../../../../../layouts/SectionHeader";
import CButton from "../../../../../components/CButton";
import { useLocation, useNavigate } from "react-router-dom";
import { IFavorite } from "../../IFavorite";
import { t } from "i18next";
import { STATIC_URL } from "../../../../../constants/constant";
import CDevider from "../../../../../components/CDevider";
import { amountAddSpace } from "../../../../../utils/utils";
import { payStore } from "../../../../../stores/payStore";

const HouseFavoritePay: React.FC = () => {
  const [isPayLoading, setIsPayLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [favorites, setFavorites] = useState<IFavorite[]>(
    JSON.parse(location.state.favorites)
  );

  const deleteFavorite = (id: number) => {
    setFavorites(favorites.filter((favorite) => favorite.id !== id));
  };

  const payAll = async () => {
    try {
      setIsPayLoading(true);

      const paymentPromises = favorites.map((favorite: IFavorite) => {
        const requestBody = {
          serviceId: favorite.serviceId,
          amount: Number(String(favorite.amount).replace(/\s/g, "")),
          account: String(favorite.account).replace(/\s/g, ""),
          fields: {} as { [key: string]: string },
        };

        if (favorite.addFields) {
          const parsedFields = JSON.parse(favorite.addFields) as {
            [key: string]: any;
          };

          Object.keys(parsedFields).forEach((key) => {
            const field = parsedFields[key];
            requestBody.fields[`field_${key}`] = String(field.id);
          });
        }

        return payStore.create(requestBody);
      });

      const payments = await Promise.all(paymentPromises);

      navigate("/payment/housefavorite/payConfirm/", {
        state: JSON.stringify(payments),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsPayLoading(false);
    }
  };

  return (
    <>
      <SectionHeader />

      <div className="housefavoritepay wrapper">
        <div className="housefavoritepay__content">
          <div className="housefavoritepay__list">
            {favorites?.map((favorite: IFavorite) => {
              return (
                <div
                  key={favorite?.id}
                  className="housefavoritepay__list__item"
                >
                  <div className="housefavoritepay__list__header">
                    <img
                      src={`${STATIC_URL}/img/services/${favorite?.serviceId}/100x100.webp`}
                      alt={favorite?.note}
                    />
                    <div>
                      <p>{favorite?.note}</p>
                      <p>{favorite?.account}</p>
                    </div>
                  </div>

                  <CDevider />

                  <div className="housefavoritepay__payment">
                    <p className="housefavoritepay__payment__title">
                      {t("favorite.paymentAmount")}
                    </p>

                    <input
                      type="text"
                      className="housefavoritepay__payment__input"
                      value={amountAddSpace(favorite?.amount)}
                      readOnly
                    />

                    <p
                      className="housefavoritepay__payment__delete"
                      onClick={() => deleteFavorite(favorite?.id)}
                    >
                      {t("favorite.deleteFromList")}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <CButton
          isLoading={isPayLoading}
          title={t("favorite.toPay")}
          onClick={payAll}
          disabled={favorites?.length === 0}
        />
      </div>
    </>
  );
};

export default HouseFavoritePay;
