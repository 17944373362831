import React, { useEffect, useState, useRef } from "react";
import "./HouseFavorite.css";
import SectionHeader from "../../../../layouts/SectionHeader";
import { Link, useLocation } from "react-router-dom";
import { RiGalleryView2 } from "react-icons/ri";
import { IoSettingsOutline, IoWalletOutline } from "react-icons/io5";
import { BsHouseAdd, BsHouseDoor } from "react-icons/bs";
import { GoPlusCircle } from "react-icons/go";
import { t } from "i18next";
import { IGroup } from "../IGroup";
import { favoriteStore } from "../../../../stores/favoriteStore";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper/modules";
import { Swiper as SwiperType } from "swiper";
import { IFavorite } from "../IFavorite";
import Favorite from "./Favorite";
import { SubmitHandler, useForm } from "react-hook-form";
import CModal from "../../../../components/CModal";
import CInput from "../../../../components/CInput";
import CButton from "../../../../components/CButton";
import { amountAddSpace } from "../../../../utils/utils";

interface IFormData {
  nameHome: string;
}

const HouseFavorite: React.FC = () => {
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [group, setGroup] = useState<IGroup>();
  const [favorites, setFavorites] = useState<IFavorite[]>([]);
  const [groupFavorites, setGroupFavorites] = useState<IFavorite[]>([]);
  const [groupFavoritesTotalAmount, setGroupFavoritesTotalAmount] = useState(0);
  const [isFavoriteLoading, setIsFavoriteLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isAddingGroup, setIsAddingGroup] = useState(false);
  const [tab, setTab] = useState("house");
  const location = useLocation();
  const [groupId, setGroupId] = useState(location.state?.groupId);
  const [initialSlide, setInitialSlide] = useState(0);
  const swiperRef = useRef<SwiperType | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormData>();

  const toggleTab = (tab: string) => {
    setTab(tab);
  };

  useEffect(() => {
    const group = groups.find((group) => group.id === groupId);

    setGroup(group);
  }, [groupId, groups]);

  const fetchGroups = async () => {
    try {
      const response = await favoriteStore.favoriteGroups();
      setGroups(response);
    } catch (error) {
      console.log("error in fetchGroups", error);
    }
  };

  const fetchFavorites = async () => {
    try {
      setIsFavoriteLoading(true);

      const response = await favoriteStore.favoritesList();

      setFavorites(response);
    } catch (error) {
      console.log("error in fetchFavorites", error);
    } finally {
      setIsFavoriteLoading(false);
    }
  };

  const addGroup: SubmitHandler<IFormData> = async (data) => {
    try {
      setIsAddingGroup(true);

      await favoriteStore.addGroup(data.nameHome);

      fetchGroups();
      toggleModal();
      reset();
    } catch (error) {
      console.log("Error in adding group", error);
    } finally {
      setIsAddingGroup(false);
    }
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    fetchGroups();
    fetchFavorites();
  }, []);

  useEffect(() => {
    if (location.state?.tab) {
      setTab(location.state.tab);
    }

    if (!location.state?.groupId && groups.length > 0) {
      setGroupId(groups[0].id);
    }
  }, [location, groups]);

  useEffect(() => {
    const groupFavorites = favorites?.filter(
      (favorite) => favorite?.groupId === groupId
    );

    const totalAmount = groupFavorites?.reduce(
      (acc, favorite) => acc + favorite.amount,
      0
    );

    setGroupFavoritesTotalAmount(totalAmount);

    setGroupFavorites(groupFavorites);
  }, [groupId, favorites]);

  useEffect(() => {
    const slideIndex = groups.findIndex((group) => group.id === groupId);
    setInitialSlide(slideIndex);

    if (swiperRef.current && slideIndex >= 0) {
      swiperRef.current.slideTo(slideIndex);
    }
  }, [groups, groupId]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setOpenModal(false);
      }
    };

    if (openModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openModal]);

  return (
    <>
      <SectionHeader backTo="/payment" />

      <div className="housefavorite wrapper">
        <div className="housefavorite__tabs">
          <div
            className={`housefavorite__tab ${tab === "house" ? "active" : ""}`}
            onClick={() => toggleTab("house")}
          >
            {t("favorite.myHouses")}
          </div>
          <div
            className={`housefavorite__tab ${
              tab === "favorite" ? "active" : ""
            }`}
            onClick={() => toggleTab("favorite")}
          >
            {t("favorite.favorite")}
          </div>
        </div>

        {tab === "house" && (
          <div className="housefavorite__content">
            {groups?.length === 0 && !isFavoriteLoading && (
              <p className="housefavorite__content__empty">
                {t("labels.noData")}
              </p>
            )}

            <div>
              <Swiper
                effect="coverflow"
                grabCursor={true}
                centeredSlides={true}
                slidesPerView="auto"
                modules={[EffectCoverflow, Pagination]}
                onSlideChange={(swiper: SwiperType) =>
                  setGroupId(groups[swiper.activeIndex]?.id)
                }
                onSwiper={(swiper: SwiperType) => {
                  swiperRef.current = swiper;
                }}
                initialSlide={initialSlide}
              >
                {groups?.map((group) => {
                  return (
                    <SwiperSlide
                      className="housefavorite__slide"
                      key={group?.id}
                    >
                      <div className="housefavorite__slide__header">
                        <h3>{group?.note}</h3>
                        <BsHouseDoor />
                      </div>

                      <div className="housefavorite__slide__content">
                        <div>
                          <p>
                            {t("favorite.paymentCount")}{" "}
                            {groupFavorites?.length}
                          </p>
                          <p>
                            {t("favorite.paymentAmount")}{" "}
                            {amountAddSpace(groupFavoritesTotalAmount)}
                          </p>
                        </div>

                        <Link
                          to={`/payment/housefavorite/houseDetail/${groupId}`}
                          state={{
                            group: JSON.stringify(group),
                            favorites: JSON.stringify(favorites),
                            groupFavorites: JSON.stringify(groupFavorites),
                          }}
                        >
                          <IoSettingsOutline />
                        </Link>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <div className="housefavorite__content__favorites">
                {isFavoriteLoading &&
                  [1, 2, 3, 4].map((item) => {
                    return (
                      <div
                        key={item}
                        className="housefavorite__favorite__loading"
                      ></div>
                    );
                  })}

                {groupFavorites?.map((favorite) => (
                  <Favorite key={favorite.id} favorite={favorite} />
                ))}
              </div>
            </div>

            <div className="housefavorite__content__footer">
              <p className="housefavorite__content__footer__item active">
                <RiGalleryView2 />
                <span> {t("favorite.choosePayment")} </span>
              </p>

              <Link
                to={`/payment/housefavorite/pay/${groupId}`}
                className="housefavorite__content__footer__item"
                state={{ favorites: JSON.stringify(groupFavorites) }}
              >
                <IoWalletOutline />
                <span> {t("favorite.payAll")} </span>
              </Link>

              <p
                className="housefavorite__content__footer__item"
                onClick={toggleModal}
              >
                <BsHouseAdd />
                <span> {t("favorite.addHouse")} </span>
              </p>

              <Link
                to={`/payment/housefavorite/houseDetail/${groupId}`}
                className="housefavorite__content__footer__item"
                state={{
                  group: JSON.stringify(group),
                  favorites: JSON.stringify(favorites),
                  groupFavorites: JSON.stringify(groupFavorites),
                }}
              >
                <GoPlusCircle />
                <span> {t("favorite.addPayment")} </span>
              </Link>
            </div>
          </div>
        )}

        {tab === "favorite" && (
          <div className="housefavorite__content">
            {favorites?.length === 0 && !isFavoriteLoading && (
              <p className="housefavorite__content__empty">
                {t("labels.noData")}
              </p>
            )}

            <div className="housefavorite__content__favorites">
              {isFavoriteLoading &&
                [1, 2, 3, 4].map((item) => {
                  return (
                    <div
                      key={item}
                      className="housefavorite__favorite__loading"
                    ></div>
                  );
                })}

              {favorites?.map((favorite) => (
                <Favorite key={favorite.id} favorite={favorite} />
              ))}
            </div>

            <div className="housefavorite__content__footer">
              <p className="housefavorite__content__footer__item active">
                <RiGalleryView2 />
                <span> {t("favorite.choosePayment")} </span>
              </p>

              <Link
                to={`/payment/housefavorite/pay/1`}
                className="housefavorite__content__footer__item"
                state={{ favorites: JSON.stringify(favorites) }}
              >
                <IoWalletOutline />
                <span> {t("favorite.payAll")} </span>
              </Link>

              <p className="housefavorite__content__footer__item">
                <GoPlusCircle />
                <span> {t("favorite.addPayment")} </span>
              </p>
            </div>
          </div>
        )}
      </div>

      <CModal
        isOpen={openModal}
        content={
          <div ref={modalRef}>
            <form
              className="widgethousefavourites__modal"
              onSubmit={handleSubmit(addGroup)}
            >
              <h2 className="widgethousefavourites__modal__title">
                {t("favorite.addHome")}
              </h2>

              <div className="widgethousefavourites__modal__input">
                <label> {t("favorite.nameHome")}</label>
                <CInput
                  placeholder={t("favorite.nameHome")}
                  {...register("nameHome", {
                    required: t("labels.required"),
                  })}
                />

                {errors.nameHome && (
                  <span className="danger__text login__error">
                    {errors.nameHome.message}
                  </span>
                )}
              </div>

              <CButton title={t("labels.add")} isLoading={isAddingGroup} />
            </form>
          </div>
        }
      />
    </>
  );
};

export default HouseFavorite;
