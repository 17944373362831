import React, { useEffect, useState } from "react";
import "./Pincode.css";
import { FiDelete } from "react-icons/fi";
import { pincodeStore } from "../../stores/pincodeStore";
import { useNavigate } from "react-router-dom";
import { TG_USER_ID } from "../../constants/constant";
import { t } from "i18next";
import CModal from "../../components/CModal";

interface ICheckPinCode {
  valid: boolean;
  set: boolean;
}

const Pincode: React.FC = () => {
  const [initPincode, setInitPincode] = useState("");
  const [pincode, setPincode] = useState("");
  const [incorrectPincode, setIncorrectPincode] = useState(false);
  const [checkPinCode, setCheckPinCode] = useState<ICheckPinCode>();
  const [pincodeMessage, setPincodeMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const fetchCheckPinCode = async (pincode: string) => {
    try {
      setIsLoading(true);
      const response = await pincodeStore.checkPinCode(pincode);

      setCheckPinCode(response.result);
    } catch (error) {
      console.log("Error checking pincode", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNumberClick = (number: string) => {
    if (initPincode.length < 4 && !checkPinCode?.set && !isLoading) {
      setInitPincode(initPincode + number);
      return;
    }

    if (pincode.length < 4) {
      setPincode(pincode + number);
    }
  };

  const handleDelete = () => {
    if (initPincode.length > 0 && !checkPinCode?.set) {
      setInitPincode(initPincode.slice(0, -1));
      return;
    }

    setPincode(pincode.slice(0, -1));
  };

  useEffect(() => {
    if (
      initPincode.length === 4 &&
      pincode.length === 4 &&
      initPincode === pincode &&
      !checkPinCode?.set
    ) {
      const setPinCode = async () => {
        try {
          const response = await pincodeStore.setPinCode(pincode, "");

          if (response?.result) {
            sessionStorage.setItem(`pincode-${TG_USER_ID}`, pincode);

            navigate("/payment");

            return;
          }
        } catch (error) {
          console.log("Error in setPinCode", error);
        }
      };

      setPinCode();
    }

    if (
      initPincode.length === 4 &&
      pincode.length === 4 &&
      initPincode !== pincode &&
      !checkPinCode?.set
    ) {
      setIncorrectPincode(true);

      setPincodeMessage(t("pincode.errorConfirmTitle"));

      setTimeout(() => {
        setIncorrectPincode(false);
        setPincode("");
      }, 500);

      return;
    }

    if (pincode.length === 4 && checkPinCode?.set) {
      const fetchCheckPinCode = async () => {
        try {
          const response = await pincodeStore.checkPinCode(pincode);

          if (response?.result.valid) {
            sessionStorage.setItem(`pincode-${TG_USER_ID}`, pincode);

            navigate("/payment");
          } else {
            setIncorrectPincode(true);

            setPincodeMessage(t("pincode.tryAgain"));

            setTimeout(() => {
              setIncorrectPincode(false);
              setPincode("");
            }, 500);
          }
        } catch (error) {
          console.log("Error checking pincode", error);
        }
      };

      fetchCheckPinCode();
    }
  }, [pincode, initPincode, navigate, checkPinCode]);

  const pincodeTitle = () => {
    if (checkPinCode?.set) {
      return t("pincode.defaultTitle");
    } else if (!checkPinCode?.set && initPincode.length === 4) {
      return t("pincode.confirmTitle");
    } else {
      return t("pincode.createTitle");
    }
  };

  const pincodeDescription = () => {
    if (checkPinCode?.set) {
      return;
    } else if (!checkPinCode?.set && initPincode.length === 4) {
      return t("pincode.confirmDesc");
    } else {
      return t("pincode.createDesc");
    }
  };

  const pincodeValue = () => {
    if (checkPinCode?.set || initPincode.length === 4) {
      return pincode
        .padEnd(4, "•")
        .split("")
        .map((_, index) => (
          <span
            key={index}
            className={`pincode__char ${
              pincode[index] ? "pincode__char--filled" : ""
            }`}
          >
            •
          </span>
        ));
    } else {
      return initPincode
        .padEnd(4, "•")
        .split("")
        .map((_, index) => (
          <span
            key={index}
            className={`pincode__char ${
              initPincode[index] ? "pincode__char--filled" : ""
            }`}
          >
            •
          </span>
        ));
    }
  };

  useEffect(() => {
    fetchCheckPinCode("");
  }, []);

  return (
    <div className="pincode wrapper">
      <div className="pincode__display">
        <h2 className="pincode__title">{pincodeTitle()}</h2>
        <p className="pincode__description">{pincodeDescription()}</p>

        <p className="pincode__error">{pincodeMessage}</p>
        <h2
          className={`pincode__value ${
            incorrectPincode && "pincode__value-message"
          }`}
        >
          {pincodeValue()}
        </h2>
      </div>

      <div className="pincode__keyboard">
        <div className="pincode__row">
          <p className="pincode__number" onClick={() => handleNumberClick("1")}>
            1
          </p>
          <p className="pincode__number" onClick={() => handleNumberClick("2")}>
            2
          </p>
          <p className="pincode__number" onClick={() => handleNumberClick("3")}>
            3
          </p>
        </div>

        <div className="pincode__row">
          <p className="pincode__number" onClick={() => handleNumberClick("4")}>
            4
          </p>
          <p className="pincode__number" onClick={() => handleNumberClick("5")}>
            5
          </p>
          <p className="pincode__number" onClick={() => handleNumberClick("6")}>
            6
          </p>
        </div>

        <div className="pincode__row">
          <p className="pincode__number" onClick={() => handleNumberClick("7")}>
            7
          </p>
          <p className="pincode__number" onClick={() => handleNumberClick("8")}>
            8
          </p>
          <p className="pincode__number" onClick={() => handleNumberClick("9")}>
            9
          </p>
        </div>

        <div className="pincode__row">
          <p className="pincode__number pincode__empty"></p>
          <p className="pincode__number" onClick={() => handleNumberClick("0")}>
            0
          </p>
          <div className="pincode__number pincode__delete">
            <FiDelete onClick={handleDelete} />
          </div>
        </div>
      </div>

      <CModal
        isOpen={pincodeStore.hasErrorOccurred}
        content={
          <h2 className="pincode__modal__title">{t("error.errorOccurred")}</h2>
        }
      />
    </div>
  );
};

export default Pincode;
