import { makeAutoObservable } from "mobx";
import apiClient from "../services/apiClient";

interface IRequstBody {
  serviceId: number;
  amount: number;
  account: string;
  fields: {
    [key: string]: string;
  };
}

class PayStore {
  constructor() {
    makeAutoObservable(this);
  }

  create = async (requestBody: IRequstBody) => {
    try {
      const response = await apiClient.post("/payments/create", requestBody);
      return response.data;
    } catch (error) {
      console.log("Error in create", error);
      throw error;
    }
  };

  payByCard = async (paymentId?: number, cardId?: number) => {
    try {
      const response = await apiClient.post("/payments/payByCard", {
        paymentId,
        cardId,
        cvv: "",
      });

      return response.data;
    } catch (error) {
      console.log("Error in payByCard", error);
    }
  };

  confirm = async (
    paymentId?: number,
    cardId?: number,
    opId?: number,
    code?: number,
    payinId?: number
  ) => {
    try {
      const response = await apiClient.post("/payments/confirm", {
        paymentId,
        cardId,
        opId,
        code,
        payinId,
      });

      return response.data;
    } catch (error) {
      console.log("Error in confirm", error);
    }
  };

  ticket = async (optoken: string) => {
    try {
      const response = await apiClient.post("/payments/ticket", {
        optoken,
      });

      return response.data;
    } catch (error) {
      console.log("Error in ticket", error);
    }
  };
}

export const payStore = new PayStore();
