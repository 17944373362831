import axios from "axios";
import {
  BASE_API_URL,
  CLIENT_SALT,
  INIT_DATA,
  TOKEN,
} from "../constants/constant";
import i18n from "../locales/i18n";
import { authStore } from "../stores/authStore";
import { pincodeStore } from "../stores/pincodeStore";

const apiClient = axios.create({
  baseURL: BASE_API_URL,
  timeout: 50000,
  headers: {
    initData: INIT_DATA,
    "Accept-Language": i18n.language,
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = authStore.token || TOKEN;
    const clientSalt = authStore.clientSalt || CLIENT_SALT;

    config.headers.Authorization = `Bearer ${token}`;
    config.headers["X-CLIENT-SALT"] = clientSalt;

    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      authStore.clear();
      pincodeStore.clear();

      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default apiClient;
