import React, { useEffect, useMemo, useState } from "react";
import "./HouseFavoritePayResult.css";
import SectionHeader from "../../../../../layouts/SectionHeader";
import { Link, useLocation } from "react-router-dom";
import CButton from "../../../../../components/CButton";
import { t } from "i18next";
import { payStore } from "../../../../../stores/payStore";
import { STATIC_URL } from "../../../../../constants/constant";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { cardStore } from "../../../../../stores/cardStore";

interface IPaymentResult {
  id: number;
  optoken: string;
  needConfirm: boolean;
}

interface ITicket {
  payinId: number;
  amountCommission: number;
  payment: {
    accTitle: string;
    account: string;
    providerId: number;
  };
}

const HouseFavoritePayResult: React.FC = () => {
  const [tickets, setTickets] = useState<ITicket[]>([]);
  const location = useLocation();
  const paymentResults = useMemo(() => JSON.parse(location.state), [location.state]);

  useEffect(() => {
    const fetchTicket = async () => {
      try {
        const responses = paymentResults?.map(async (payment: IPaymentResult) => {
          return await payStore.ticket(payment.optoken);
        });
  
        const results = await Promise.all(responses);
        setTickets(results);
      } catch (error) {
        console.log("Error in fetchTicket", error);
      }
    };
  
    fetchTicket();
  }, [paymentResults]);

  useEffect(() => {
    cardStore.reGetList();

    setTimeout(() => {
      cardStore.getTotalAmount();
    }, 2000);
  }, []);

  return (
    <>
      <SectionHeader />

      <div className="ident housefavoritepayresult wrapper">
        <div className="housefavoritepayresult__list">
          {tickets?.map((ticket: ITicket) => {
            return (
              <div
                key={ticket?.payinId}
                className="housefavoritepayresult__item"
              >
                <img
                  src={`${STATIC_URL}/img/services/${ticket?.payment?.providerId}/100x100.webp`}
                  alt={ticket?.payment?.account}
                  className="housefavoritepayresult__item__img"
                />

                <div className="housefavoritepayresult__item__fields">
                  <div className="housefavoritepayresult__item__field">
                    <p>{ticket?.payment?.accTitle}</p>
                    <p>{ticket?.payment?.account}</p>
                  </div>

                  <div className="housefavoritepayresult__item__field">
                    <p>{t("favorite.paymentAmount")}</p>
                    <p>{ticket?.amountCommission}</p>
                  </div>

                  <div className="housefavoritepayresult__item__field">
                    <p></p>
                    <IoCheckmarkCircleOutline className="housefavoritepayresult__item__mark" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <Link to={"/payment"}>
          <CButton title={t("favorite.returnMain")} />
        </Link>
      </div>
    </>
  );
};

export default HouseFavoritePayResult;
