import futureRu from "../assets/images/stories/futureRu.jpg";
import oneRu from "../assets/images/stories/oneRu.jpg";
import securityRu from "../assets/images/stories/securityRu.jpg";
import inviteRu from "../assets/images/stories/inviteRu.jpg";
import completeRu from "../assets/images/stories/completeRu.jpg";
import futureUz from "../assets/images/stories/futureUz.jpg";
import oneUz from "../assets/images/stories/oneUz.jpg";
import securityUz from "../assets/images/stories/securityUz.jpg";
import inviteUz from "../assets/images/stories/inviteUz.jpg";
import completeUz from "../assets/images/stories/completeUz.jpg";
import welcomeRu from "../assets/images/stories/welcomeRu.gif";
import easyRu from "../assets/images/stories/easyRu.gif";
import welcomeUz from "../assets/images/stories/welcomeUz.gif";
import easyUz from "../assets/images/stories/easyUz.gif";

interface IStoryImage {
  type: string;
  url: string;
  duration: number;
}

const params = new URLSearchParams(window.location.search);
const lang = params.get("lang")?.toLowerCase();

export const storyImages: IStoryImage[] = [
  {
    type: "image",
    url: lang === "uz" ? welcomeUz : welcomeRu,
    duration: 6000,
  },
  {
    type: "image",
    url: lang === "uz" ? easyUz : easyRu,
    duration: 4000,
  },
  {
    type: "image",
    url: lang === "uz" ? futureUz : futureRu,
    duration: 3000,
  },
  {
    type: "image",
    url: lang === "uz" ? oneUz : oneRu,
    duration: 3000,
  },
  {
    type: "image",
    url: lang === "uz" ? securityUz : securityRu,
    duration: 3000,
  },
  {
    type: "image",
    url: lang === "uz" ? inviteUz : inviteRu,
    duration: 3000,
  },
  {
    type: "image",
    url: lang === "uz" ? completeUz : completeRu,
    duration: 3000,
  },
];
