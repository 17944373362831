import React, { useEffect, useState } from "react";
import "./WidgetHouseFavourites.css";
import { TbHeartPlus, TbHomePlus } from "react-icons/tb";
import CDevider from "../../../components/CDevider";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { favoriteStore } from "../../../stores/favoriteStore";
import { HiOutlineHome } from "react-icons/hi2";
import CModal from "../../../components/CModal";
import CInput from "../../../components/CInput";
import CButton from "../../../components/CButton";
import { SubmitHandler, useForm } from "react-hook-form";
import { IGroup } from "./IGroup";
import { IFavorite } from "./IFavorite";
import { STATIC_URL } from "../../../constants/constant";
import AllServices from "./AllServices";

interface IFormData {
  nameHome: string;
}

const WidgetHouseFavourites: React.FC = () => {
  const [favorites, setFavorites] = useState<IFavorite[]>([]);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [isFavoriteModalOpen, setIsFavoriteModalOpen] = useState(false);
  const [isAddingGroup, setIsAddingGroup] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormData>();

  const fetchGroups = async () => {
    try {
      const response = await favoriteStore.favoriteGroups();
      setGroups(response);
    } catch (error) {
      console.log("error in fetchGroups", error);
    }
  };

  const fetchFavorites = async () => {
    try {
      const response = await favoriteStore.favoritesList();
      setFavorites(response);
    } catch (error) {
      console.log("error in fetchFavorites", error);
    }
  };

  const addGroup: SubmitHandler<IFormData> = async (data) => {
    try {
      setIsAddingGroup(true);
      await favoriteStore.addGroup(data.nameHome);
      
      fetchGroups();
      toggleModal();
      reset();
    } catch (error) {
      console.log("Error in adding group", error);
    } finally {
      setIsAddingGroup(false);
    }
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const toggleFavoriteModal = () => {
    setIsFavoriteModalOpen(!isFavoriteModalOpen);
  };

  useEffect(() => {
    fetchGroups();
    fetchFavorites();
  }, []);

  return (
    <div className="widgethousefavourites">
      <div className="widgethousefavourites__header">
        <div>
          <Link
            to={"/payment/housefavorite"}
            state={{ tab: "favorite" }}
            className="widgethousefavourites__header__title"
          >
            <h2>
              {t("labels.favourites")} ({favorites?.length})
            </h2>
          </Link>
          <Link
            to={"/payment/housefavorite"}
            state={{ tab: "house" }}
            className="widgethousefavourites__header__title"
          >
            <h2>
              {t("labels.house")} ({groups?.length})
            </h2>
          </Link>
        </div>
        <div className="widgethousefavourites__header__content">
          <div onClick={toggleFavoriteModal}>
            <TbHeartPlus className="widgethousefavourites__header__icon" />
          </div>
          <div onClick={toggleModal}>
            <TbHomePlus className="widgethousefavourites__header__icon" />
          </div>
        </div>
      </div>

      <CDevider />

      <div className="widgethousefavourites__content">
        {groups?.map((group: IGroup) => (
          <Link
            to={"/payment/housefavorite"}
            className="widgethousefavourites__card"
            key={group?.id}
            state={{ tab: "house", groupId: group?.id }}
          >
            <HiOutlineHome className="widgethousefavourites__card__img" />
            <p className="widgethousefavourites__card__note">{group?.note}</p>
          </Link>
        ))}

        {favorites?.map((favorite: IFavorite) => (
          <Link
            to={`/payment/housefavorite/favoritePay/${favorite?.serviceId}`}
            className="widgethousefavourites__card"
            key={favorite?.id}
            state={{ favorite: JSON.stringify(favorite) }}
          >
            <img
              src={`${STATIC_URL}/img/services/${favorite?.serviceId}/100x100.webp`}
              alt={favorite?.note}
              className="widgethousefavourites__card__img"
            />
            <p className="widgethousefavourites__card__note">
              {favorite?.note}
            </p>
          </Link>
        ))}

        {groups?.length === 0 &&
          favorites?.length === 0 &&
          [1, 2, 3, 4].map((i) => (
            <div key={i} className="widgethousefavourites__card"></div>
          ))}
      </div>

      <CModal
        isOpen={openModal}
        onToggle={toggleModal}
        content={
          <div>
            <form
              className="widgethousefavourites__modal"
              onSubmit={handleSubmit(addGroup)}
            >
              <h2 className="widgethousefavourites__modal__title">
                {t("favorite.addHome")}
              </h2>

              <div className="widgethousefavourites__modal__input">
                <label> {t("favorite.nameHome")}</label>
                <CInput
                  placeholder={t("favorite.nameHome")}
                  {...register("nameHome", {
                    required: t("labels.required"),
                  })}
                />

                {errors.nameHome && (
                  <span className="danger__text login__error">
                    {errors.nameHome.message}
                  </span>
                )}
              </div>

              <CButton title={t("labels.add")} isLoading={isAddingGroup} />
            </form>
          </div>
        }
      />

      <CModal
        isOpen={isFavoriteModalOpen}
        onToggle={toggleFavoriteModal}
        content={<AllServices />}
      />
    </div>
  );
};

export default WidgetHouseFavourites;
