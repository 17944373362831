import { makeAutoObservable } from "mobx";
import apiClient from "../services/apiClient";
import { TG_USER_ID } from "../constants/constant";

class PincodeStore {
  hasErrorOccurred: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  checkPinCode = async (pinCode?: string) => {
    try {
      const response = await apiClient.post("/account/checkPinCode", {
        pinCode,
      });

      return response.data;
    } catch (error) {
      this.hasErrorOccurred = true;
      console.log("Error in checkPinCode", error);
    }
  };

  setPinCode = async (pinCode?: string, oldPinCode?: string) => {
    try {
      const response = await apiClient.post("/account/setPinCode", {
        pinCode,
        oldPinCode,
      });

      return response.data;
    } catch (error) {
      console.log("Error in setPinCode", error);
    }
  };

  clear = () => {
    sessionStorage.removeItem(`needPincode-${TG_USER_ID}`);
  };
}

export const pincodeStore = new PincodeStore();
