import React, { useEffect, useState } from "react";
import "./Payment.css";
import { paymentServiceStore } from "../../stores/paymentServiceStore";
import { themeStore } from "../../stores/themeStore";
import { RiGalleryView2 } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import CCardSkeleton from "../../components/CCardSkeleton";
import { Link } from "react-router-dom";
import { STATIC_URL } from "../../constants/constant";
import { IPaymentService } from "../../models/PaymentService";
import CPaymentCategoryWidget from "./CPaymentCategoryWidget";
import HotNews from "./HotNews";
import { IPaymentServiceCategory } from "../../models/IPaymentServiceCategory";
import Cards from "./Cards";
import WidgetHouseFavourites from "./WidgetHouseFavourites";
import { cardStore } from "../../stores/cardStore";

const Payment: React.FC = () => {
  const [categories, setCategories] = useState<IPaymentServiceCategory[]>([]);
  const [popularServices, setPopularServices] = useState<IPaymentService[]>([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [isLoadingPopularService, setIsLoadingPopularService] = useState(false);
  const { t } = useTranslation();
  const skeleton = [1, 2, 3, 4];

  const fetchServices = async () => {
    try {
      await paymentServiceStore.getPaymentServices();
    } catch (error) {
      console.log("Error in getPaymentServices", error);
    }
  };

  const fetchCategories = async () => {
    try {
      setIsLoadingCategories(true);
      const response = await paymentServiceStore.getPaymentServiceCategories();

      setCategories(response);
    } catch (error) {
      console.log("Error in getPaymentCategories", error);
    } finally {
      setIsLoadingCategories(false);
    }
  };

  const fetchPopularServices = async () => {
    try {
      setIsLoadingPopularService(true);
      const response = await paymentServiceStore.getPaymentServicePopular();

      setPopularServices(response);
    } catch (error) {
      console.log("Error in getPaymentPopular", error);
    } finally {
      setIsLoadingPopularService(false);
    }
  };

  const getTotalAmount = async () => {
    try {
      await cardStore.getTotalAmount();
    } catch (error) {
      console.log("Error in getTotalAmount", error);
    }
  };

  useEffect(() => {
    fetchServices();
    fetchCategories();
    fetchPopularServices();

    setTimeout(() => {
      getTotalAmount();
    }, 3000);
  }, []);

  return (
    <div className="payment wrapper">
      <HotNews />

      <Cards />

      <WidgetHouseFavourites />

      <div className="payment__service">
        <Link
          to={"/payment/allcategories"}
          className="payment__service__header"
        >
          <h2 className="payment__service__title">
            {t("labels.confident_services")}
          </h2>

          <RiGalleryView2 className="payment__service__all" />
        </Link>

        <div className="payment__service__list">
          {isLoadingCategories &&
            skeleton.map((item) => <CCardSkeleton key={item} />)}

          {categories?.map((category) => (
            <Link
              to={`/payment/destination/${category.id}`}
              key={category.id}
              className="payment__service__item"
            >
              <img
                src={
                  themeStore.theme === "light"
                    ? category.img
                    : category.img_white
                }
                alt={category.name}
                className="payment__service__item__img"
              />
              <p className="payment__service__item__name">{category.name}</p>
            </Link>
          ))}
        </div>
      </div>

      <div className="payment__service">
        <Link
          to={"/payment/popularservices"}
          className="payment__service__header"
        >
          <h2 className="payment__service__title">
            {t("labels.popular_services")}
          </h2>

          <RiGalleryView2 className="payment__service__all" />
        </Link>

        <div className="payment__service__list">
          {isLoadingPopularService &&
            skeleton.map((item) => <CCardSkeleton key={item} />)}

          {popularServices?.map((popularService) => (
            <Link
              to={`/payment/service/${popularService.id}`}
              state={{ service: JSON.stringify(popularService) }}
              key={popularService.id}
              className="payment__service__item"
            >
              <img
                src={`${STATIC_URL}//img/services/${popularService.id}/100x100.webp`}
                alt={popularService.name}
                className="payment__service__item__img payment__service__item__img--popular"
              />
              <p className="payment__service__item__name">
                {popularService.name}
              </p>
            </Link>
          ))}
        </div>
      </div>

      <div className="payment__widgets">
        <CPaymentCategoryWidget categoryId="18" title={t("payment.games")} />
        <CPaymentCategoryWidget
          categoryId="14"
          title={t("payment.governmentServices")}
        />
      </div>
    </div>
  );
};

export default Payment;
