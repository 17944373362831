import React, { useState } from "react";
import "./HouseDetail.css";
import SectionHeader from "../../../../../layouts/SectionHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import CInput from "../../../../../components/CInput";
import { RiDeleteBinLine } from "react-icons/ri";
import CButton from "../../../../../components/CButton";
import { useForm } from "react-hook-form";
import { favoriteStore } from "../../../../../stores/favoriteStore";
import CModal from "../../../../../components/CModal";
import { GoPlusCircle } from "react-icons/go";
import { IFavorite } from "../../IFavorite";
import { STATIC_URL } from "../../../../../constants/constant";
import { TbHeartPlus } from "react-icons/tb";

interface FormData {
  note: string;
}

const HouseDetail: React.FC = () => {
  const location = useLocation();
  const [isEditingGroup, setIsEditingGroup] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const group = JSON.parse(location.state.group);
  const [groupFavorites, setGroupFavorites] = useState(
    JSON.parse(location.state.groupFavorites)
  );
  const [favorites, setFavorites] = useState(() => {
    const initialFavorites = JSON.parse(location.state.favorites);
    return initialFavorites.filter(
      (fav: IFavorite) =>
        !groupFavorites.some((groupFav: IFavorite) => groupFav.id === fav.id) &&
        (fav.groupId === group.id || fav.groupId === 0)
    );
  });
  const navigate = useNavigate();

  const defaultNote = group.note || "";
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>({
    defaultValues: {
      note: defaultNote,
    },
  });
  const currentNote = watch("note");
  const isButtonEnabled = currentNote !== defaultNote;

  const editGroup = async (data: FormData) => {
    try {
      setIsEditingGroup(true);

      await favoriteStore.editGroup(group.id, data.note);
    } catch (error) {
      console.log("error in editing group", error);
    } finally {
      setIsEditingGroup(false);
    }
  };

  const deleteGroup = async () => {
    try {
      await favoriteStore.deleteGroup(group.id, true);

      navigate("/payment/housefavorite");
    } catch (error) {
      console.log("error in deleting group", error);
    }
  };

  const editFavoriteGroup = async (favoriteId: number, groupId: number) => {
    try {
      await favoriteStore.editFavoriteGroup(favoriteId, groupId);

      if (groupId === 0) {
        setGroupFavorites(
          groupFavorites.filter(
            (favorite: IFavorite) => favorite.id !== favoriteId
          )
        );

        const favorite = groupFavorites.find(
          (favorite: IFavorite) => favorite.id === favoriteId
        );

        setFavorites([...favorites, favorite]);
      }

      if (groupId !== 0) {
        const favorite = favorites.find(
          (favorite: IFavorite) => favorite.id === favoriteId
        );

        setGroupFavorites([...groupFavorites, favorite]);

        setFavorites(
          favorites.filter((favorite: IFavorite) => favorite.id !== favoriteId)
        );
      }
    } catch (error) {
      console.log("error in editing favorite group", error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return (
    <>
      <SectionHeader />

      <div className="housedetail wrapper">
        <div className="housedetail__content">
          <div className="housedetail__form">
            <p className="housedetail__form__label">{t("favorite.nameHome")}</p>
            <div>
              <CInput
                placeholder={t("favorite.nameHome")}
                {...register("note", {
                  required: t("labels.required"),
                })}
              />
              <RiDeleteBinLine
                className="housedetail__delete__icon"
                onClick={toggleModal}
              />
            </div>

            {errors.note && (
              <span className="danger__text">{errors.note.message}</span>
            )}
          </div>

          <div className="housedetail__add__payment">
            <p>{t("favorite.addPayment")}</p>
            <GoPlusCircle />
          </div>

          <div className="housedetail__payments">
            <p className="housedetail__payments__title">
              {t("favorite.addedPayments")}
            </p>

            {groupFavorites?.length === 0 && (
              <p className="housedetail__payments__nodata">
                {t("labels.noData")}
              </p>
            )}

            <div className="housedetail__payments__list">
              {groupFavorites?.map((favorite: IFavorite) => (
                <div className="housedetail__payments__item" key={favorite.id}>
                  <img
                    src={`${STATIC_URL}/img/services/${favorite?.serviceId}/100x100.webp`}
                    alt={`${favorite?.serviceId}`}
                    className="housedetail__payments__item__img"
                  />

                  <div className="housedetail__payments__item__content">
                    <p>{favorite?.note}</p>
                    <p>{favorite?.account}</p>
                    <p>{favorite?.amount}</p>
                  </div>

                  <RiDeleteBinLine
                    className="housedetail__payments__item__delete"
                    onClick={() => editFavoriteGroup(favorite?.id, 0)}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="housedetail__payments">
            <p className="housedetail__payments__title">
              {t("favorite.addServicesFromFavorites")}
            </p>

            {favorites?.length === 0 && (
              <p className="housedetail__payments__nodata">
                {t("labels.noData")}
              </p>
            )}

            <div className="housedetail__payments__list">
              {favorites?.map((favorite: IFavorite) => (
                <div className="housedetail__payments__item" key={favorite.id}>
                  <img
                    src={`${STATIC_URL}/img/services/${favorite?.serviceId}/100x100.webp`}
                    alt={`${favorite?.serviceId}`}
                    className="housedetail__payments__item__img"
                  />

                  <div className="housedetail__payments__item__content">
                    <p>{favorite?.note}</p>
                    <p>{favorite?.account}</p>
                    <p>{favorite?.amount}</p>
                  </div>

                  <TbHeartPlus
                    className="housedetail__payments__item__add"
                    onClick={() => editFavoriteGroup(favorite?.id, group?.id)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <CButton
          variant="success"
          title={t("button.save")}
          onClick={handleSubmit(editGroup)}
          disabled={!isButtonEnabled}
          isLoading={isEditingGroup}
        />
      </div>

      <CModal
        isOpen={isOpenModal}
        onToggle={toggleModal}
        content={
          <div className="housedetail__modal">
            <div className="housedetail__modal__content">
              <p>{t("labels.areYouSureToDelete")}</p>
            </div>

            <div className="housedetail__modal__actions">
              <CButton title={t("button.cancel")} onClick={toggleModal} />
              <CButton
                title={t("button.delete")}
                variant="danger"
                onClick={deleteGroup}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default HouseDetail;
