import React, { useState, useEffect } from "react";
import "./Transfer.css";
import CButton from "../../components/CButton";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import CInput from "../../components/CInput";
import { useForm } from "react-hook-form";
import { useHookFormMask } from "use-mask-input";
import { amountAddSpace } from "../../utils/utils";
import { cardStore } from "../../stores/cardStore";
import { ITransferDirection } from "../../models/ITransferDirection";
import { STATIC_URL } from "../../constants/constant";
import CCardSkeleton from "../../components/CCardSkeleton";
import CStatusIcon from "../../components/CStatusIcon";
import { IoIosMore } from "react-icons/io";
import { IAccountInfo } from "../../models/IAccountInfo";
import { accountStore } from "../../stores/accountStore";
import { favoriteStore } from "../../stores/favoriteStore";
import { CiCreditCard1 } from "react-icons/ci";

interface ITransferValues {
  cardNumber: string;
  amount: string;
}

interface IFavouriteCard {
  id: number;
  amount: number;
  note: string;
  account: string;
}

const Transfer: React.FC = () => {
  const [p2pInfo, setP2PInfo] = useState();
  const [transferDirections, setTransferDirections] = useState<
    ITransferDirection[]
  >([]);
  const [favoriteCards, setFavoriteCards] = useState<IFavouriteCard[]>([]);
  const [isLoadingTransferDir, setIsLoadingTransferDir] = useState(false);
  const [selectedDirection, setSelectedDirection] =
    useState<ITransferDirection>();
  const [accountInfo, setAccountInfo] = useState<IAccountInfo>();
  const [cardNumber, setCardNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isIdent, setIsIdent] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm<ITransferValues>();

  const registerWithMask = useHookFormMask(register);

  const getP2PInfo = async (cardNumber: string) => {
    try {
      const response = await cardStore.p2pInfo(cardNumber.replaceAll(" ", ""));

      setMessage(response.message);

      setP2PInfo(response.result);
    } catch (error) {
      console.log("Error in getP2PInfo", error);
    }
  };

  const fetchTransferDirections = async () => {
    try {
      setIsLoadingTransferDir(true);
      const response = await cardStore.transferDirections();

      setSelectedDirection(response.result[0]);

      setTransferDirections(response.result);
    } catch (error) {
      console.log("Error in fetchTransferDirections", error);
    } finally {
      setIsLoadingTransferDir(false);
    }

    try {
      const response = await favoriteStore.favoritesList();

      const cards = response.filter(
        (item: IFavouriteCard) => item.account.length === 16
      );

      setFavoriteCards(cards);
    } catch (error) {
      console.log("Error in getFavoriteCards", error);
    }
  };

  const getAccountInfo = async () => {
    try {
      const response = await accountStore.getAccountInfo();

      setAccountInfo(response);
    } catch (error) {
      console.log("Error in getAccountInfo", error);
    }
  };

  const handleSelectDirection = (direction: ITransferDirection) => {
    setSelectedDirection(direction);
  };

  const handleSelectCard = (id: number) => {
    const card = favoriteCards.find((item) => item.id === id);

    if (card) {
      setCardNumber(card.account);
      setAmount(card.amount.toString());

      setValue("cardNumber", card.account);
      setValue("amount", card.amount.toString());

      trigger(["cardNumber", "amount"]);
    }
  };

  const onSubmit = async (data: ITransferValues) => {
    try {
      setIsLoading(true);

      const response = await cardStore.createCardTransaction(
        data.cardNumber.replaceAll(" ", ""),
        data.amount.replaceAll(" ", "")
      );

      setMessage(response.message);

      if (response.id) {
        navigate(`/transfer/confirm/${response.id}`, {
          state: { transaction: response },
        });
      }
    } catch (error) {
      console.log("Error on creating card transaction", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCardNumber(e.target.value);
    setValue("cardNumber", e.target.value);
    trigger("cardNumber");
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const unmaskedCardNumber = cardNumber.replace(/[\s_]/g, "");
      if (unmaskedCardNumber.length === 16) {
        getP2PInfo(unmaskedCardNumber);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [cardNumber]);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/\D/g, "");

    setAmount(rawValue);
    setValue("amount", rawValue);
    trigger("amount");
  };

  const fetchIsIdent = async () => {
    try {
      const response = await accountStore.isIdentAccount();

      setIsIdent(response);

      return response;
    } catch (error) {
      console.log("Error in fetchIsIdent", error);
    }
  };

  const status = () => {
    if (isIdent) {
      return "Gold";
    }

    if (!isIdent) {
      return "Silver";
    }

    if (accountInfo?.levelId === 2) {
      return "Gold";
    }

    if (accountInfo?.levelId === 3) {
      return "Gold";
    }

    if (accountInfo?.levelId === 4) {
      return "Platinum";
    }

    return "Silver";
  };

  useEffect(() => {
    fetchTransferDirections();
    getAccountInfo();
    fetchIsIdent();
  }, []);

  return (
    <div className="transfer wrapper">
      <div className="transfer__content">
        {favoriteCards?.length > 0 && (
          <div className="transfer__favorites">
            <p className="transfer__favorites__title">
              {t("transfer.favTransfer")}
            </p>

            <div className="transfer__favorites__list">
              {favoriteCards?.map((card) => {
                return (
                  <div
                    className="transfer__favorites__item"
                    key={card?.id}
                    onClick={() => handleSelectCard(card?.id)}
                  >
                    <CiCreditCard1 className="transfer__favorites__item__icon" />
                    <p className="transfer__favorites__item__note">
                      {card?.note}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="transfer__directions">
          <p className="transfer__directions__title">
            {t("transfer.dirTitle")}
          </p>

          <div className="transfer__directions__list">
            {isLoadingTransferDir &&
              [...Array(4)].map((_, index) => <CCardSkeleton key={index} />)}

            <div className="transfer__directions__status">
              <Link
                to={"/profile/ident"}
                className="transfer__directions__icon"
              >
                <CStatusIcon status={status()} />
              </Link>
              <div className="transfer__directions__more">
                <IoIosMore />
              </div>
            </div>

            {transferDirections.map((direction) => (
              <div
                key={direction.id}
                className={`transfer__direction ${
                  selectedDirection && selectedDirection.id === direction.id
                    ? "transfer__direction-selected"
                    : ""
                }`}
                onClick={() => handleSelectDirection(direction)}
              >
                <img
                  src={`${STATIC_URL}/img/transDirs/${direction.id}.png`}
                  alt={direction.title}
                  className="transfer__direction__img"
                />
                <p className="transfer__direction__title">{direction.title}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="transfer__field">
          <p className="transfer__label">{t("transfer.titleCard")}</p>

          <CInput
            inputMode="numeric"
            placeholder="____ ____ ____ ____"
            maxLength={19}
            {...registerWithMask("cardNumber", ["9999 9999 9999 9999"], {
              required: t("labels.required"),
            })}
            onChange={handleCardNumberChange}
            value={cardNumber}
          />
          {errors.cardNumber && (
            <span className="transfer__error__message">
              {errors.cardNumber.message} <br />
            </span>
          )}

          {message && <span className="error__message">{message}</span>}

          {p2pInfo && (
            <p className="transfer__cardOwner">
              {t("aboutTransaction.ownerCard")}: {p2pInfo}
            </p>
          )}
        </div>

        <div className="transfer__field">
          <p className="transfer__label">{t("transfer.titleAmount")}</p>

          <input
            placeholder="0"
            className="transfer__input"
            value={amountAddSpace(Number(amount))}
            {...register("amount", {
              required: t("labels.required"),
              validate: {
                min: (value) => {
                  const numValue = Number(value.replace(/\s/g, ""));
                  return (
                    numValue >= 1000 || t("error.minAmount", { min: 1000 })
                  );
                },
                max: (value) => {
                  const numValue = Number(value.replace(/\s/g, ""));
                  return (
                    numValue <= 27000000 ||
                    t("error.maxAmount", { max: 27000000 })
                  );
                },
              },
            })}
            onChange={handleAmountChange}
            inputMode="numeric"
          />
          {errors.amount && (
            <span className="transfer__error__message">{errors.amount.message}</span>
          )}
        </div>
      </div>

      <div className="transfer__actions">
        <Link to={"/payment"} className="transfer__link">
          <CButton title={t("button.goHome")} />
        </Link>

        <CButton
          title={t("button.resume")}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default Transfer;
