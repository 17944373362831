import React, { useCallback, useEffect, useState } from "react";
import "./FavoritePay.css";
import CBack from "../../../../components/CBack";
import { t } from "i18next";
import { MdOutlineDelete } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { favoriteStore } from "../../../../stores/favoriteStore";
import CModal from "../../../../components/CModal";
import CButton from "../../../../components/CButton";
import CChooseService from "../../../../components/CChooseService";
import { IPaymentService } from "../../../../models/PaymentService";
import { paymentServiceStore } from "../../../../stores/paymentServiceStore";
import { IServiceFieldItem } from "../../PaymentService/PaymentService.types";
import { useForm } from "react-hook-form";
import { useHookFormMask } from "use-mask-input";
import { payStore } from "../../../../stores/payStore";
import { IPaymentServiceField } from "../../../../models/PaymentServiceField";
import CInput from "../../../../components/CInput";
import CSelect from "../../../../components/CSelect";
import { amountAddSpace } from "../../../../utils/utils";

const FavoritePay: React.FC = () => {
  const [amount, setAmount] = useState("");
  const [service, setService] = useState<IPaymentService>({});
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectValues, setSelectValues] = useState<{
    [key: string]: IServiceFieldItem | undefined;
  }>({});
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const favorite = JSON.parse(location.state.favorite);

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm();

  const registerWithMask = useHookFormMask(register);

  const onSubmit = async (data: any) => {
    const fieldData = Object.entries(data).reduce((acc, [key, value]) => {
      if (key.startsWith("field_")) {
        acc[key] = String(value).replace(/\s/g, "");
      }
      return acc;
    }, {} as Record<string, string>);

    const selectValuesArray = Object.entries(selectValues).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [`field_${key}`]: String(value?.id),
      }),
      {}
    );

    const { amount, account } = data;

    const requestBody = {
      serviceId: service.id || 0,
      amount: Number(String(amount).replace(/\s/g, "")),
      account: String(account).replace(/\s/g, ""),
      fields: {
        ...fieldData,
        ...selectValuesArray,
      },
    };

    try {
      setIsLoading(true);
      const response = await payStore.create(requestBody);

      if (response.message) {
        setMessage(response.message);
        return;
      }

      if (response) {
        navigate(`/payment/serviceConfirm/${response.id}`, {
          state: JSON.stringify(response),
        });
      }
    } catch (error) {
      console.log("Error in create", error);
    } finally {
      setIsLoading(false);
    }
  };

  const editFavorite = async (data: any) => {
    try {
      setIsEditing(true);

      const fieldData = Object.entries(data).reduce((acc, [key, value]) => {
        if (key.startsWith("field_")) {
          acc[key] = String(value).replace(/\s/g, "");
        }
        return acc;
      }, {} as Record<string, string>);

      const selectValuesArray = Object.entries(selectValues).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [`field_${key}`]: String(value?.id),
        }),
        {}
      );

      await favoriteStore.editFavorite(
        favorite?.id,
        Number(String(data?.amount).replace(/\s/g, "")),
        favorite?.groupId,
        data?.account,
        data?.note,
        {
          ...fieldData,
          ...selectValuesArray,
        }
      );
    } catch (error) {
      console.log("error in editFavorite", error);
    } finally {
      setIsEditing(false);
    }
  };

  const deleteFavorite = async () => {
    try {
      await favoriteStore.deleteFavorite(favorite?.id);

      navigate("/payment");
    } catch (error) {
      console.log("error in deleteFavorite", error);
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/\D/g, "");

    setAmount(rawValue);
    setValue("amount", rawValue);
    trigger("amount");
  };

  const handleSelectChange = (fieldId: string, option: IServiceFieldItem) => {
    setSelectValues((prev) => ({
      ...prev,
      [fieldId]: option,
    }));
  };

  useEffect(() => {
    reset({
      note: favorite?.note,
      account: favorite?.account,
      amount: favorite?.amount,
    });

    const amount = String(favorite?.amount).replace(/\D/g, "");
    setAmount(amount);
    setValue("amount", amount);
    trigger("amount");

    const addFields = JSON.parse(favorite?.addFields || "{}");

    if (service?.fields) {
      const initialSelectValues: any = {};
      service?.fields.forEach((field: any) => {
        const addField = addFields[field.id];
        if (addField) {
          initialSelectValues[field.id] = field.items.find(
            (item: any) => item.id === addField.id
          );
        }
      });
      setSelectValues(initialSelectValues);
    }
  }, [
    reset,
    setValue,
    trigger,
    favorite?.note,
    favorite?.account,
    favorite?.amount,
    favorite?.addFields,
    service?.fields,
  ]);

  const fetchServices = useCallback(async () => {
    try {
      const response = await paymentServiceStore.getPaymentServices();

      const service = response.find(
        (service: IPaymentService) => service?.id === favorite?.serviceId
      );

      setService(service);
    } catch (error) {
      console.log("error in fetchServices", error);
    }
  }, [favorite]);

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  return (
    <div className="favoritepay">
      <div className="favoritepay__header wrapper">
        <CBack />

        <p className="favoritepay__header__title">
          {t("aboutTransaction.payment")}
        </p>

        <MdOutlineDelete
          className="favoritepay__header__icon"
          onClick={toggleModal}
        />
      </div>

      <div
        className="favoritepay__content wrapper"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <div className="paymentService__content">
          <CChooseService service={service} />

          <div className="paymentService__fields">
            <div className="paymentService__field">
              <p className="paymentService__field__title">
                {t("favorite.description")}
              </p>

              <CInput
                className="paymentService__input-field"
                {...register("note", {
                  required: t("labels.required"),
                })}
              />

              {errors.note && (
                <p className="paymentService__field__error">
                  {t("labels.required")}
                </p>
              )}
            </div>

            {service?.fields?.map((field: IPaymentServiceField) => {
              return (
                <div className="paymentService__field" key={field.id}>
                  <p className="paymentService__field__title">{field?.title}</p>

                  {field?.type === "input" && (
                    <CInput
                      placeholder={field?.placeholder}
                      preLabel={field?.preLabel}
                      {...registerWithMask(
                        `field_${field.id}`,
                        [service?.displayMask?.replaceAll("X", "9") || ""],
                        {
                          required: t("labels.required"),
                        }
                      )}
                      className="paymentService__input-field"
                    />
                  )}

                  {field?.type === "select" && (
                    <CSelect
                      options={field?.items}
                      placeholder={field?.placeholder || t("labels.choose")}
                      value={selectValues[String(field.id)]}
                      onChange={(option) =>
                        handleSelectChange(String(field.id), option)
                      }
                    />
                  )}

                  {errors[`field_${field.id}`] && (
                    <p className="paymentService__field__error">
                      {t("labels.required")}
                    </p>
                  )}
                </div>
              );
            })}

            <div className="paymentService__field">
              <p className="paymentService__field__title">
                {service?.accountTitle}
              </p>

              <CInput
                placeholder={service?.accountPlaceholder}
                preLabel={service?.preLabel}
                className={`${service.isCard ? "cinput-card" : ""} ${
                  service?.fields && service?.fields?.length > 0
                    ? "paymentService__input-field"
                    : ""
                }`}
                inputMode={
                  service?.isCard || service?.onlyNums ? "numeric" : "text"
                }
                {...registerWithMask(
                  "account",
                  [
                    service?.isCard
                      ? "9999 9999 9999 9999"
                      : service?.displayMask?.replaceAll("X", "9") || "",
                  ],
                  {
                    required: t("labels.required"),
                  }
                )}
              />

              {errors.account && (
                <p className="paymentService__field__error">
                  {t("labels.required")}
                </p>
              )}
            </div>

            <div className="paymentService__field">
              <p className="paymentService__field__title">
                {t("transfer.titleAmount")}
              </p>

              <input
                placeholder="0"
                className="paymentService__input"
                value={amountAddSpace(Number(amount))}
                inputMode="numeric"
                {...register("amount", {
                  required: t("labels.required"),
                  validate: {
                    min: (value) => {
                      if (Number(service.min) !== 0) {
                        const numValue = Number(value.replace(/\s/g, ""));
                        return (
                          numValue >= Number(service.min) ||
                          t("error.minAmount", { min: service.min })
                        );
                      }
                    },
                    max: (value) => {
                      if (Number(service.max) !== 0) {
                        const numValue = Number(value.replace(/\s/g, ""));
                        return (
                          numValue <= Number(service.max) ||
                          t("error.maxAmount", { max: Number(service.max) })
                        );
                      }
                    },
                  },
                })}
                onChange={handleAmountChange}
              />

              {errors.amount && (
                <p className="paymentService__field__error">
                  {typeof errors.amount.message === "string"
                    ? errors.amount.message
                    : t("labels.required")}
                </p>
              )}

              {message && <p className="error__message">{message}</p>}
            </div>
          </div>
        </div>

        <div>
          <div className="paymentService__actions">
            <CButton
              title={t("button.save")}
              variant="success"
              onClick={handleSubmit(editFavorite)}
              isLoading={isEditing}
            />

            <CButton
              title={t("button.resume")}
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>

      <CModal
        isOpen={isOpenModal}
        onToggle={toggleModal}
        content={
          <div className="housedetail__modal">
            <div className="housedetail__modal__content">
              <p>{t("labels.areYouSureToDelete")}</p>
            </div>

            <div className="housedetail__modal__actions">
              <CButton title={t("button.cancel")} onClick={toggleModal} />
              <CButton
                title={t("button.delete")}
                variant="danger"
                onClick={deleteFavorite}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default FavoritePay;
