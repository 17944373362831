import React, { useCallback, useEffect, useState } from "react";
import "./ServiceResult.css";
import SectionHeader from "../../../../layouts/SectionHeader";
import { Link, useLocation } from "react-router-dom";
import { themeStore } from "../../../../stores/themeStore";
import pwDark from "../../../../assets/images/pw-dark.svg";
import pwLight from "../../../../assets/images/pw-light.svg";
import CButton from "../../../../components/CButton";
import CLoading from "../../../../components/CLoading";
import CStatusTicket from "../../../../components/CStatusTicket";
import CDevider from "../../../../components/CDevider";
import { payStore } from "../../../../stores/payStore";
import { cardStore } from "../../../../stores/cardStore";
import { pointStore } from "../../../../stores/pointStore";
import { t } from "i18next";

interface IField {
  note: string;
  value: string;
}

export interface ITicket {
  id: number;
  amount: number;
  amountCommission: number;
  canceled: boolean;
  favouriteSaved: boolean;
  fields: IField[];
}

const ServiceResult: React.FC = () => {
  const [ticket, setTicket] = useState<ITicket>();
  const [inviteLink, setInviteLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const state = location.state.response;

  const getTicket = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await payStore.ticket(state.optoken);
      setTicket(response);
    } catch (error) {
      console.log("Error in getTicket", error);
    } finally {
      setIsLoading(false);
    }
  }, [state.optoken]);

  const fetchInviteLink = async () => {
    try {
      const response = await pointStore.getInvite();

      setInviteLink(response?.result);
    } catch (error) {
      console.log("Error in fetchInviteLink", error);
    }
  };

  const shareTicket = () => {
    if (!ticket) return;

    const ticketDetails = ticket.fields
      .filter((field) => field.note !== "Назначение")
      .map((field) => {
        let icon = "ℹ️";

        if (field.note === "Номер транзакции" || field.note === "Номер чека") {
          icon = "#️⃣";
        } else if (field.note === "Оплачено с карты") {
          icon = "💳";
        } else if (
          field.note === "Сумма зачисления" ||
          field.note === "Оплачено" ||
          field.note === "Комиссия" ||
          field.note === "Сумма к оплате"
        ) {
          icon = "💵";
        } else if (field.note === "Время зачисления") {
          icon = "🕓";
        }
        return `${icon}${field.note}: ${field.value}`;
      })
      .join("\n");

    const shareMessage = `✅${t(
      "labels.paymentByPayWayApp"
    )}\n--------------\n${ticketDetails}\n--------------\n${inviteLink}`;

    if (window.Telegram?.WebApp?.openTelegramLink) {
      window.Telegram.WebApp.openTelegramLink(
        `https://t.me/share/url?url=${encodeURIComponent(shareMessage)}`
      );
    }
  };

  useEffect(() => {
    getTicket();
  }, [getTicket]);

  useEffect(() => {
    cardStore.reGetList();
    fetchInviteLink();

    setTimeout(() => {
      cardStore.getTotalAmount();
    }, 2000);
  }, []);

  return (
    <>
      <SectionHeader title={t("transaction.resultTitle")} backTo="/" />

      <div className="serviceresult wrapper">
        <div>
          <div
            className="center"
            style={{ flexDirection: "column", gap: "10px" }}
          >
            <img
              src={themeStore.isDarkTheme ? pwDark : pwLight}
              alt="pw"
              className="serviceresult__img"
            />

            <div className="center">{isLoading && <CLoading />}</div>
          </div>

          {!isLoading && (
            <div className="serviceresult__fields">
              <CStatusTicket status="done" />

              <CDevider />

              {ticket?.fields?.map((field, index) => {
                return (
                  <div key={index} className="serviceresult__field">
                    <p className="serviceresult__note">{field.note}</p>
                    <p className="serviceresult__value">{field.value}</p>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="serviceresult__actions">
          <CButton title={t("button.share")} onClick={shareTicket} />

          <Link to={"/payment"} className="serviceresult__btn">
            <CButton title={t("button.goHome")} />
          </Link>
        </div>
      </div>
    </>
  );
};

export default ServiceResult;
