import React, { useState } from "react";
import "./DeleteAccountConfirm.css";
import { SubmitHandler, useForm } from "react-hook-form";
import CBack from "../../../components/CBack";
import { themeStore } from "../../../stores/themeStore";
import CInput from "../../../components/CInput";
import { t } from "i18next";
import CButton from "../../../components/CButton";
import pwLight from "../../../assets/images/pw-light.svg";
import pwDark from "../../../assets/images/pw-dark.svg";
import { accountStore } from "../../../stores/accountStore";
import { TG_USER_ID } from "../../../constants/constant";

interface IFormData {
  code: string;
}

const DeleteAccountConfirm: React.FC = () => {
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormData>();

  const onSubmit: SubmitHandler<IFormData> = async (data) => {
    try {
      setIsLoading(true);
      const response = await accountStore.deleteConfirm(data.code);

      if (response.message) {
        setMessage(response.message);
        return;
      }

      if (response) {
        localStorage.removeItem(`token-${TG_USER_ID}`);
        localStorage.removeItem(`clientSalt-${TG_USER_ID}`);

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      console.error("Error during delete account confirm:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="deleteAccountConfirm wrapper">
      <div style={{ width: "100%" }}>
        <CBack />
      </div>

      <img
        src={themeStore.isDarkTheme ? pwDark : pwLight}
        alt="pw"
        className="auth__logo"
      />

      <form
        className="deleteAccountConfirm__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <p className="deleteAccountConfirm__form__label">{t("code.title")}</p>
          <CInput
            placeholder={t("code.placeholder")}
            inputMode="numeric"
            maxLength={6}
            {...register("code", {
              required: t("error.codeRequired"),
              minLength: {
                value: 6,
                message: t("error.codeMinLength"),
              },
              maxLength: {
                value: 6,
                message: t("error.codeMaxLength"),
              },
            })}
          />

          {errors.code && (
            <span className="danger__text deleteAccountConfirm__error">
              {errors.code.message}
            </span>
          )}

          {message && <p className="error__message">{message}</p>}
        </div>

        <CButton
          title={t("labels.confirm")}
          variant="success"
          isLoading={isLoading}
        />
      </form>
    </div>
  );
};

export default DeleteAccountConfirm;
