import React, { useEffect, useState } from "react";
import "./Exchange.css";
import { useTranslation } from "react-i18next";
import SectionHeader from "../../../layouts/SectionHeader";
import { pointStore } from "../../../stores/pointStore";
import CInput from "../../../components/CInput";
import CButton from "../../../components/CButton";
import { useForm } from "react-hook-form";
import { amountAddSpace } from "../../../utils/utils";
import { cardStore } from "../../../stores/cardStore";
import { Link, useNavigate } from "react-router-dom";
import CModal from "../../../components/CModal";
import { ICard } from "../../Payment/Cards/ICard";

interface IPointAmount {
  earnedAmount?: number;
  receivedAmount?: number;
  startDate?: string;
  endDate?: string;
}

interface IExchangeValues {
  amount: string;
}

const Exchange: React.FC = () => {
  const [cardNumber, setCardNumber] = useState("");
  const [pointAmount, setPointAmount] = useState<IPointAmount>();
  const [amount, setAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm<IExchangeValues>();

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/\D/g, "");

    setAmount(rawValue);
    setValue("amount", rawValue);
    trigger("amount");
  };

  const onSubmit = async (data: IExchangeValues) => {
    const amount = data.amount.replace(/\s/g, "");

    setIsOpenModal(true);

    try {
      setIsLoading(true);

      const response = await cardStore.createCardTransaction(
        cardNumber,
        amount
      );

      if (response.message) {
        setMessage(response.message);
        return;
      }

      if (response) {
        navigate(`/point/exchange/confirm/${response.id}`, { state: response });
      }
    } catch (error) {
      console.log("Error in onSubmit", error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    navigate("/point");
  };

  const getPointAmount = async () => {
    try {
      const response = await pointStore.getAmount();

      setPointAmount(response);
    } catch (error) {
      console.log("Error in getPointAmount", error);
    }
  };

  const getCardList = async () => {
    try {
      const response = await cardStore.getList();

      const card = response?.find(
        (card: ICard) => card.note === "Humo Virtual"
      );

      if (!card) {
        setIsOpenModal(true);
        return;
      }

      const cardNumber = await cardStore.getCardNumber(card.id);

      setCardNumber(cardNumber.result);
    } catch (error) {
      console.log("Error in getCardList", error);
    }
  };

  useEffect(() => {
    getPointAmount();
    getCardList();
  }, []);

  return (
    <>
      <SectionHeader />

      <div className="exchange wrapper">
        <div>
          <h3 className="exchange__title">{t("point.exchangeTitle")}</h3>
          <p className="exchange__desc">{t("point.exchangeDesc")}</p>
        </div>

        <div className="exchange__content">
          <div>
            <p className="exchange__content__title">{t("point.yourBalance")}</p>
            <CInput
              value={String(
                amountAddSpace(Number(pointAmount?.earnedAmount) || 0)
              )}
              readonly
              className="exchange__content__input"
            />
          </div>

          <div>
            <p className="exchange__content__title">
              Введите количество которые хотите обменять
            </p>

            <CInput
              className="exchange__content__input"
              placeholder="0"
              value={amountAddSpace(Number(amount))}
              {...register("amount", {
                required: t("labels.required"),
                validate: {
                  min: (value) => {
                    const numValue = Number(value.replace(/\s/g, ""));
                    return (
                      numValue >= Number(50000) ||
                      t("error.minAmount", { min: 50000 })
                    );
                  },
                  max: (value) => {
                    const numValue = Number(value.replace(/\s/g, ""));
                    return (
                      (pointAmount?.earnedAmount &&
                        numValue <= pointAmount?.earnedAmount) ||
                      t("error.maxAmountPoint", {
                        max: pointAmount?.earnedAmount,
                      })
                    );
                  },
                },
              })}
              onChange={handleAmountChange}
              inputMode="numeric"
            />

            {errors.amount && (
              <span className="error__message">{errors.amount.message}</span>
            )}

            {message && <p className="error__message">{message}</p>}
          </div>

          <CButton
            title={t("point.exchange")}
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
          />
        </div>

        <div>
          <p className="exchange__help">{t("point.help")}</p>
        </div>
      </div>

      {!cardNumber && (
        <CModal
          content={
            <div>
              <p className="exchange__modal__title">
                {t("point.exchangeCreateVirtualCard")}
              </p>

              <div className="exchange__modal__action">
                <CButton title={t("button.cancel")} onClick={toggleModal} />
                <Link to={"/payment/createVirtualCard"}>
                  <CButton title={t("button.create")} variant="success" />
                </Link>
              </div>
            </div>
          }
          isOpen={isOpenModal}
        />
      )}
    </>
  );
};

export default Exchange;
