import React, { useCallback, useEffect, useState } from "react";
import "./HouseFavoritePayConfirm.css";
import SectionHeader from "../../../../../layouts/SectionHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { ICard } from "../../../Cards/ICard";
import { cardStore } from "../../../../../stores/cardStore";
import CCardSwiper from "../../../../../components/CCardSwiper";
import CButton from "../../../../../components/CButton";
import { t } from "i18next";
import { STATIC_URL } from "../../../../../constants/constant";
import { MdOutlineDelete } from "react-icons/md";
import { amountAddSpace } from "../../../../../utils/utils";
import { payStore } from "../../../../../stores/payStore";

interface IField {
  note: string;
  value: string;
}

interface IPayment {
  id: number;
  optoken: string;
  fields: IField[];
  payment: {
    providerId: number;
    amountCommission: number;
  };
}

const HouseFavoritePayConfirm: React.FC = () => {
  const [cards, setCards] = useState<ICard[]>([]);
  const [selectedCard, setSelectedCard] = useState<ICard>();
  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const [isPayConfirming, setIsPayConfirming] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [payments, setPayments] = useState<IPayment[]>(() => {
    try {
      const initialPayments: IPayment[] = JSON.parse(location.state);

      const filteredPayments = initialPayments.filter((payment) => payment.id);
      return filteredPayments;
    } catch (error) {
      console.error("Error parsing location.state:", error);
      return [];
    }
  });

  const fetchCards = useCallback(async () => {
    try {
      setIsLoadingCard(true);

      let response = await cardStore.getList();

      response = response.filter((card: ICard) => card.type !== "19");

      setSelectedCard(response[0]);

      setCards(response);
    } catch (error) {
      console.log("Error in fetchCards in serviceConfirm", error);
    } finally {
      setIsLoadingCard(false);
    }
  }, []);

  const deletePayment = (id: number) => {
    setPayments(payments.filter((payment: IPayment) => payment.id !== id));
  };

  const confirmByCard = async () => {
    try {
      setIsPayConfirming(true);

      const paymentPromises = payments?.map((payment) => {
        return payStore.payByCard(payment?.id, Number(selectedCard?.id));
      });

      const paymentResults = await Promise.all(paymentPromises);

      navigate("/payment/housefavorite/payResult", {
        state: JSON.stringify(paymentResults),
      });
    } catch (error) {
      console.log("Error in confirmByCard in HouseFavoritePayConfirm", error);
    } finally {
      setIsPayConfirming(false);
    }
  };

  const getTotalAmount = () => {
    return payments?.reduce((acc, payment) => {
      return acc + payment?.payment?.amountCommission;
    }, 0);
  };

  useEffect(() => {
    fetchCards();
  }, [fetchCards]);
  return (
    <>
      <SectionHeader />

      <div className="housefavoritepayconfirm wrapper">
        <div>
          <div className="housefavoritepayconfirm__list">
            {payments?.map((payment: IPayment) => {
              return (
                <div key={payment.id} className="housefavoritepayconfirm__item">
                  <img
                    src={`${STATIC_URL}/img/services/${payment?.payment?.providerId}/100x100.webp`}
                    alt={payment?.optoken}
                    className="housefavoritepayconfirm__item__img"
                  />

                  <div className="housefavoritepayconfirm__fields">
                    {payment?.fields?.map((field: IField, index: number) => {
                      return (
                        <div
                          key={index}
                          className="housefavoritepayconfirm__field"
                        >
                          <p className="housefavoritepayconfirm__field__note">
                            {field.note}
                          </p>
                          <p className="housefavoritepayconfirm__field__value">
                            {field.value}
                          </p>
                        </div>
                      );
                    })}

                    <div className="housefavoritepayconfirm__field">
                      <p className="housefavoritepayconfirm__field__note">
                        {t("aboutTransaction.amountPayment")}
                      </p>
                      <p className="housefavoritepayconfirm__field__value">
                        {amountAddSpace(payment?.payment?.amountCommission)}
                      </p>
                    </div>
                    <div className="housefavoritepayconfirm__delete">
                      <MdOutlineDelete
                        onClick={() => deletePayment(payment?.id)}
                        className="housefavoritepayconfirm__delete__icon"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="housefavoritepayconfirm__actions">
          <p className="housefavoritepayconfirm__actions__message">
            {t("aboutTransaction.amountDebit")}:{" "}
            {amountAddSpace(getTotalAmount())} {t("aboutTransaction.sum")}
          </p>

          {cards?.length > 0 && (
            <CCardSwiper
              cards={cards}
              setSelectedCard={setSelectedCard}
              isLoading={isLoadingCard}
            />
          )}

          <CButton
            title={t("button.confirm")}
            onClick={confirmByCard}
            isLoading={isPayConfirming}
            disabled={cards?.length === 0 || payments?.length === 0}
          />
        </div>
      </div>
    </>
  );
};

export default HouseFavoritePayConfirm;
