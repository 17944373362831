import React, { useEffect, useState } from "react";
import "./Reset.css";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CBack from "../../../components/CBack";
import { themeStore } from "../../../stores/themeStore";
import CInput from "../../../components/CInput";
import { t } from "i18next";
import CButton from "../../../components/CButton";
import pwLight from "../../../assets/images/pw-light.svg";
import pwDark from "../../../assets/images/pw-dark.svg";
import { authStore } from "../../../stores/authStore";
import CModal from "../../../components/CModal";

interface IFormData {
  code: string;
}

const Reset: React.FC = () => {
  const [message, setMessage] = useState("");
  const [timer, setTimer] = useState(60);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormData>();

  const navigate = useNavigate();

  useEffect(() => {
    let interval: NodeJS.Timer | undefined;

    if (isTimerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsTimerActive(false);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isTimerActive, timer]);

  const onSubmit: SubmitHandler<IFormData> = async (data) => {
    try {
      setIsLoading(true);

      const response = await authStore.passwordResetConfirm(data?.code);

      if (response.message) {
        setMessage(response.message);
        return;
      }

      if (response.result) {
        setIsOpenModal(true);
      }
    } catch (error) {
      console.error("Error during login confirm:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="reset wrapper">
      <div style={{ width: "100%" }}>
        <CBack />
      </div>

      <img
        src={themeStore.isDarkTheme ? pwDark : pwLight}
        alt="pw"
        className="auth__logo"
      />

      <form className="reset__form" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <p className="reset__form__label">{t("code.title")}</p>
          <CInput
            placeholder={t("code.placeholder")}
            inputMode="numeric"
            maxLength={6}
            {...register("code", {
              required: t("error.codeRequired"),
              minLength: {
                value: 6,
                message: t("error.codeMinLength"),
              },
              maxLength: {
                value: 6,
                message: t("error.codeMaxLength"),
              },
            })}
          />

          {errors.code && (
            <span className="danger__text reset__error">
              {errors.code.message}
            </span>
          )}

          {message && <p className="error__message">{message}</p>}

          <p className="auth__timer">
            {timer} {t("labels.sec")}
          </p>
        </div>

        <CButton
          title={t("labels.confirm")}
          variant="success"
          isLoading={isLoading}
        />
      </form>

      <CModal
        isOpen={isOpenModal}
        content={
          <div className="reset__modal">
            <p className="reset__modal__title">{t("auth.resetNotify")}</p>

            <CButton
              title={t("button.ok")}
              onClick={() => navigate("/auth/login")}
            />
          </div>
        }
      />
    </div>
  );
};

export default Reset;
