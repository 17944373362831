import { makeAutoObservable } from "mobx";
import apiClient from "../services/apiClient";
import { pincodeStore } from "./pincodeStore";

class AccountStore {
  accountInfoCache = null;
  accountSessionsCache = null;
  isIdentCache = null;
  userLevelsCache = null;

  constructor() {
    makeAutoObservable(this);
  }

  getAccountInfo = async () => {
    if (this.accountInfoCache) {
      return this.accountInfoCache;
    }

    try {
      const response = await apiClient.get("/account/info");

      this.accountInfoCache = response.data;

      return response.data;
    } catch (error) {
      console.log("Error in getAccountInfo", error);
    }
  };

  identification = async (
    country?: string,
    firstName?: string,
    lastName?: string,
    middleName?: string,
    documentSerial?: string,
    documentNumber?: string,
    documentIssuer?: string,
    city?: string,
    address?: string,
    birthPlace?: string,
    documentExpire?: string,
    documentIssued?: string,
    birthDate?: string,
    selfie?: File,
    document1?: File,
    document2?: File
  ) => {
    try {
      const formData = new FormData();

      formData.append("country", country || "");
      formData.append("firstName", firstName || "");
      formData.append("lastName", lastName || "");
      formData.append("middleName", middleName || "");
      formData.append("documentSerial", documentSerial || "");
      formData.append("documentNumber", documentNumber || "");
      formData.append("documentIssuer", documentIssuer || "");
      formData.append("city", city || "");
      formData.append("address", address || "");
      formData.append("birthPlace", birthPlace || "");
      formData.append("documentExpire", documentExpire || "");
      formData.append("documentIssued", documentIssued || "");
      formData.append("birthDate", birthDate || "");

      if (selfie) {
        formData.append("selfie", selfie);
      }

      if (document1) {
        formData.append("document1", document1);
      }

      if (document2) {
        formData.append("document2", document2);
      }

      const response = await apiClient.post(
        "/account/identification",
        formData
      );

      this.accountInfoCache = response.data;

      return response.data;
    } catch (error) {
      console.log("Error in identification", error);
    }
  };

  isIdentAccount = async () => {
    if (this.isIdentCache !== null) {
      return this.isIdentCache;
    }

    try {
      const response = await apiClient.get("/account/isIdent");

      this.isIdentCache = response.data.result;

      return response.data.result;
    } catch (error) {
      console.log("Error in isIdentAccount", error);
    }
  };

  onIdent = async () => {
    try {
      const response = await apiClient.get("/account/onIdent");

      return response.data;
    } catch (error) {
      console.log("Error in onIdent ", error);
    }
  };

  fetchUserLevels = async () => {
    if (this.userLevelsCache) {
      return this.userLevelsCache;
    }

    try {
      const response = await apiClient.get("/account/userLevels");

      this.userLevelsCache = response.data;
      return response.data;
    } catch (error) {
      console.log("Error in fetchUserLevels", error);
    }
  };

  fetchSessions = async () => {
    if (this.accountSessionsCache) {
      return this.accountSessionsCache;
    }

    try {
      const response = await apiClient.get("/account/sessions");

      this.accountSessionsCache = response.data;
      return response.data;
    } catch (error) {
      console.log("Error in fetchSessions", error);
    }
  };

  acceptOffer = async () => {
    try {
      const response = await apiClient.patch("/account/acceptOffer");

      return response.data;
    } catch (error) {
      console.log("Error in acceptOffer", error);
    }
  };

  closeSession = async (sessionId: number) => {
    try {
      const response = await apiClient.patch(
        `/account/closeSession/${sessionId}`
      );

      this.accountSessionsCache = null;

      return response.data;
    } catch (error) {
      console.log("Error in closeSession", error);
    }
  };

  logout = async () => {
    try {
      const response = await apiClient.get("/account/logout");

      this.clear();
      pincodeStore.clear();

      return response.data;
    } catch (error) {
      console.log("Error in logout", error);
    }
  };

  delete = async () => {
    try {
      const response = await apiClient.delete("/account");

      return response.data;
    } catch (error) {
      console.log("Error in delete", error);
    }
  };

  deleteConfirm = async (code: string) => {
    try {
      const response = await apiClient.patch("/account/deleteConfirm", {
        code,
      });

      this.clear();
      pincodeStore.clear();

      return response.data;
    } catch (error) {
      console.log("Error in deleteConfirm", error);
    }
  };

  clear = () => {
    this.accountInfoCache = null;
    this.accountSessionsCache = null;
    this.isIdentCache = null;
    this.userLevelsCache = null;
  };
}

export const accountStore = new AccountStore();
